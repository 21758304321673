import React from 'react'
import AfricaImg from '../assets/Img/africa.png'
import Pointers from '../assets/Img/pointers.png'
import { BsFillCheckCircleFill } from 'react-icons/bs'

function Hero() {
    return (
        <section className='w-full lg:h-screen md:h-screen pt-36 relative pb-10 lg:pb-0'>

            <div className="lg:w-[80vw] w-[85vw] h-full mx-auto grid lg:grid-cols-2 md:grid-cols-2 gap-10">
                <div className="flex flex-col justify-center lg:relative z-20 w-full mb-10 lg:mb-0">
                    <div className="flex flex-col gap-5 md:relative z-20">
                        <h1 className='lg:text-[48px] text-[35.1915px] text-primary-purple font-extrabold drop-shadow-lg custom relative'>Learn Africa Language And Culture With Ease</h1>

                        <img src={Pointers} alt="" className='absolute lg:-top-10 lg:-left-16 md:top-[-1.5em] md:left-[-3em] left-[-0.5em] top-[7em] z-10 lg:w-20 w-14' />
                        <p className='md:text-md sm:text-sm'>
                            Speak Africa.io is the biggest innovation that provides access to deep and rich cultural values beautifully crafted on our cultural and traditional languages Speak Africa is designed with built-in assistant to help individuals:

                        </p>
                        <div>
                            <p className='flex gap-3 mb-3 lg:text-[1em] text-[0.95em]'> <span> <BsFillCheckCircleFill className='text-primary drop-shadow-xl' /></span>  Learn your favorite language on the go.</p>
                            <p className='flex gap-3 mb-3 lg:text-[1em] text-[0.95em]'> <span> <BsFillCheckCircleFill className='text-primary drop-shadow-xl' /></span> Access variety of standby African language interpreters 24/7.</p>
                            <p className='flex gap-3 lg:text-[1em] text-[0.95em]'> <span> <BsFillCheckCircleFill className='text-primary drop-shadow-xl' /></span> A variety of opportunities to teach African languages to a global audience as a verified language expert (across the hundreds of languages that exists in Africa)</p>
                        </div>
                        {/* <div className='border border-primary md:w-[75%] justify-between flex p-2 rounded-md bg-[#F4F3FF]'>
                            <input type="text" placeholder='Enter email address' className='bg-transparent focus:outline-0 lg:w-full pl-2' />
                            <Button value={'Join Waitlist'} variant={'sm'} />
                        </div> */}
                    </div>
                </div>
                <div className="lg:w-full flex justify-center items-center">
                    <img src={AfricaImg} alt="" className='relative z-20' />
                </div>
            </div>
        </section>
    )
}
export default Hero