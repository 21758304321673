import React, { useEffect } from 'react'
// import { Button } from '../components/Button'
import { Header } from '../components/Header'
import { Section } from '../components/section'
import shake from "../assets/Img/icons/shake.png";
import PhoneSection from '../components/PhoneSection';
import health from '../assets/Img/benefits/health.png'
import colleague from '../assets/Img/benefits/colleague.png'
import development from '../assets/Img/benefits/development.png'
import leave from '../assets/Img/benefits/leave.png'

const OurTeam = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });//always scroll to top
        document.title = 'Speak Africa | Our Team'
    })

    const buttons_1 = ['Finance', 'Data & Risk', 'Designer', 'Engineering', 'Commercial', 'Corporate Affairs']
    const buttons_2 = ['Legal & Compliance', 'Product & Design', 'Customer Operations']
    const mobileButton = ['Finance', 'Data & Risk', 'Designer', 'Engineering', 'Commercial', 'Customer Operations', 'Legal & Compliance', 'Product & Design', 'Customer Operations']
    const jobs = [
        {
            role: 'Accountant',
            dept: 'Finance',
            location: 'Remote - USA'
        },
        {
            role: 'Backend Engineer',
            dept: 'Engineering',
            location: 'Remote'
        },
        {
            role: 'Card Operations',
            dept: 'Customer Operations',
            location: 'Remote - USA'
        },
        {
            role: 'Product Designer',
            dept: 'Branding',
            location: 'Remote - USA'
        },
        {
            role: 'Customer Support',
            dept: 'Customer Service',
            location: 'Remote - USA'
        },

    ]
    return (
        <div className='min-w-screen relative z-20'>

            <section className='w-full pt-36 pb-10 lg:pb-0'>
                <div className="lg:w-[95vw] w-[85vw] mx-auto gap-10 md:gap-0">
                    {/* Header section */}
                    <section>
                        <Header
                            variant={1}
                            title="Careers At Speak Africa"
                            subtitle="Join us to achieve our goal by building  a great future for African language & culture"
                            className="mb-2 text-[24px] lg:text-[50px]  text-center"
                            fontSize='text-center text-[15px] lg:text-[20px]'
                        />

                        <Header
                            variant={2}
                            title="Current job openings"
                            className="text-[20px] lg:text-[24px] font-[700] font-Nirmala UI text-center my-4"
                        />


                        <form>
                            <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                            <div className="relative lg:w-[1071px]  m-auto">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                    </svg>
                                </div>
                                <input
                                    type="search"
                                    id="default-search"
                                    className="block w-full py-3 lg:py-4 p-2 pl-12 text-sm text-gray-900 border border-primary-purple rounded-lg bg-white focus:border-primary-purple focus:outline-none"
                                    placeholder="Search Job..."
                                />
                            </div>
                        </form>

                    </section>
                    {/* End of header section */}


                    {/* Button Section */}
                    <section className='buttons mb-10 block lg:hidden'>
                        <p className='text-center text-[#363E7A] text-[16.7423px] lg:text-[20px] font-[350] my-4'>Filter By Department</p>
                        <div className="my-3 flex flex-wrap justify-center gap-2">
                            {mobileButton.map((name) => (
                                <button
                                    // type="button"
                                    className="py-2.5 px-3 mb-2 lg:min-w-[118px] lg:max-w-[170px]: text-[12.2275px] lg:text-sm font-medium text-[#6E63ED] focus:outline-none bg-[#E8E8FF] rounded-[6px] border border-gray-200 focus:ring"
                                >
                                    {name}
                                </button>
                            ))}

                        </div>
                    </section>
                    <section className='buttons mb-10 hidden lg:block'>
                        <p className='text-center text-[#363E7A] text-[16.7423px] lg:text-[20px] font-[350] my-4'>Filter By Department</p>
                        <div className="my-3 flex flex-wrap justify-center gap-7">
                            {buttons_1.map((name) => (
                                <button
                                    // type="button"
                                    className="py-2.5 px-3 mb-2 lg:min-w-[118px] lg:max-w-[170px]: text-[12.2275px] lg:text-sm font-medium text-[#6E63ED] focus:outline-none bg-[#E8E8FF] rounded-[6px] border border-gray-200 focus:ring"
                                >
                                    {name}
                                </button>
                            ))}

                        </div>
                        <div className="my-3 flex lg:flex-row  lg:justify-center gap-7">
                            {buttons_2.map((name) => (
                                <button
                                    // type="button"
                                    className="py-2.5 px-2 min-w-[147px] max-w-[160px] lg:min-w-[197px] lg:max-w-[217px] mb-2 text-[12.2275px] lg:text-sm font-medium text-[#6E63ED] focus:outline-none rounded-[6px] border bg-[#E8E8FF] focus:ring"
                                >
                                    {name}
                                </button>
                            ))}
                        </div>
                    </section>

                    <section className='buttons mb-20'>
                        <div className='flex justify-center'>
                            <button
                                // type="button"
                                className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-white focus:outline-none bg-[#56D3A2] rounded-[6px] border border-gray-200 focus:ring block"
                            >
                                Reset Filter
                            </button>
                        </div>

                        {jobs.map(({ role, dept, location }) => (
                            <div className='h-[51px] lg:h-[106px] lg:w-[1009.98px] rounded-[9.56656px] lg:rounded-[20px] m-auto bg-[#F8FDF9] flex gap-2 lg:gap-0 justify-between items-center my-4 lg:px-10 px-3'
                                data-aos="fade-in"
                            >

                                <div>
                                    <h6 className='text-[11.9582px] lg:text-[25px]'>{role}</h6>
                                    <p className='text-[8.60991px] lg:text-[18px]'>{dept}</p>
                                </div>
                                <p className='text-[11.9582px] lg:text-[25px]'>{location}</p>
                                <button
                                    className={'lg:h-[43.75px] lg:w-[141.98px] h-[25.93px] w-[67.91px] p-[3.9px] lg:p-[8.25472px] rounded-[3px] lg:rounded-[5px] bg-primary-purple text-white'}
                                    // onClick={() => onClick()}
                                    // data-aos="fade-in"
                                >
                                    <p className={'text-[16px] leading-[20px] lg:text-[26.4151px] lg:leading-[32px]'}>{'Apply'}</p>
                                </button>
                            </div>
                        ))}
                    </section>

                    {/* End of button section */}



                    {/* Benefits Section */}

                    <section className='w-full lg:w-[80] mx-auto lg:p-6 lg:mb-20'>

                        <Header
                            variant={1}
                            title='Benefits'
                            subtitle='In addition to a competitive salary, here are a few resources we offer to help our team excel.'
                            className='text-[24px] lg:text-[50px]'
                            fontSize='text-[16px] lg:text-[20px] mb-5'
                        />

                        <div className='grid grid-flow-row lg:grid-flow-col lg:gap-3 my-4'>
                            <div className='flex flex-col lg:flex-row justify-evenly items-center w-[50] gap-2 mb-3 lg:mb-0'
                                data-aos="fade-in"
                            >
                                <img src={health} alt="" className='w-[86.04px] lg:w-[100px] h-[86.04px] lg:h-[100px]' />
                                <div>
                                    <h3 className='text-24px text-primary-purple text-center lg:text-left h-[32px] font-[700] leading-[32px] lg:mb-3'>Health Insurance</h3>
                                    <p className='text-[13.7658px] lg:text-16px font-[350] lg:leading-[140%] lg:w-[339px] h-[44px] text-center lg:text-left'>Our comprehensive medical insurance covers a wide variety of needs</p>
                                </div>
                            </div>
                            <div className='flex flex-col lg:flex-row justify-evenly items-center gap-2 mb-3 lg:mb-0'
                                data-aos="fade-in"
                            >
                                <img src={colleague} alt="" className='w-[86.04px] lg:w-[100px] h-[86.04px] lg:h-[100px]' />
                                <div>
                                    <h3 className='text-24px text-primary-purple text-center lg:text-left h-[32px] font-[700] leading-[32px] lg:mb-3'>Smart, Kind Colleagues</h3>
                                    <p className='text-[13.7658px] lg:text-16px font-[350] lg:leading-[140%] lg:w-[339px] text-center lg:text-left'>Learn and grow with highly accomplished colleagues who are invested in your personal and professional development</p>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-flow-row lg:grid-flow-col gap-3 my-4'>
                            <div className='flex flex-col lg:flex-row justify-evenly items-center w-[50] gap-2 mb-3 lg:mb-0'
                                data-aos="fade-in"
                            >
                                <img src={development} alt="" className='w-[86.04px] lg:w-[100px] h-[86.04px] lg:h-[100px]' />
                                <div>
                                    <h3 className='text-24px text-primary-purple text-center lg:text-left h-[32px] font-[700] leading-[32px] lg:mb-3'>Professional Development</h3>
                                    <p className='text-[13.7658px] lg:text-16px font-[350] lg:leading-[140%] lg:w-[339px] text-center lg:text-left'>We invest in our people to ensure they can achieve their short, medium, and long term professional goals</p>
                                </div>
                            </div>
                            <div className='flex flex-col lg:flex-row justify-evenly items-center gap-2 mb-3 lg:mb-0'
                                data-aos="fade-in"
                            >
                                <img src={leave} alt="" className='w-[86.04px] lg:w-[100px] h-[86.04px] lg:h-[100px]' />
                                <div>
                                    <h3 className='text-24px text-primary-purple text-center lg:text-left h-[32px] font-[700] lg:leading-[32px] lg:mb-3'>Annual Leave</h3>
                                    <p className='text-[13.7658px] lg:text-16px font-[350] lg:leading-[140%] lg:w-[339px] text-center lg:text-left'>All team members have up to 30 days of annual leave, and are warmly encouraged to use them</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End of benefits section */}

                    <section className='w-full pt-36 pb-10 lg:pb-0 mb-20'>
                        <div className="w-[85vw] mx-auto gap-10 md:gap-0">
                            <PhoneSection />
                        </div>
                    </section>
                    {/*  Sections */}

                    <section className="mb-20">
                        <Section
                            type={3}

                            title={'Do You Wish To Partner With Us?'}
                            image={shake}
                            content='For partnership and investment, email us at:'
                        />
                    </section>


                </div>
            </section>
        </div>
    )
}

export default OurTeam