import React from 'react'
import { Button } from './Button'

export const Section = ({ type, image, title, content, className, fontSize, id, onClick, btnText, link, linkText }) => {
    return (
        <section section className='w-full py-10'>
            {
                // Image on left
                type === 1 &&
                <div className={`${id % 2 === 0 ? 'lg:flex flex-row-reverse' : 'lg:flex'} w-[full] h-full mx-auto gap-10 lg:gap-0`}
                    data-aos="fade-up"
                >

                    <div className="flex justify-center lg:w-[50%] lg:mb-0 mb-10">
                        <img src={image} alt="" />
                    </div>

                    <div className={`${id % 2 === 0 ? 'lg:mx-auto flex justify-end' : ''} lg:w-[50%] lg:my-auto`}>

                        <div className=' lg:w-[70%]'>

                            <h1 className={`${className} text-[55px] text-primary-purple font-[700] leading-[68.48px] mb-5 drop-shadow-xl`}>{title}</h1>
                            <p className={`${fontSize} lg:text-md text-[16px] font-light lg:w-50 text-[#0A1017]`}>{content}</p>

                        </div>

                    </div>

                </div>
            }
            {
                // image on right, card with title header and button
                type === 2 &&
                <div className={`${id % 2 === 0 ? 'lg:flex flex-row-reverse' : 'lg:flex'} w-[full] h-full mx-auto gap-10 lg:gap-0`}
                    data-aos="fade-up"
                >

                    <div className="flex justify-center lg:w-[50%] lg:mb-0 mb-10">
                        <img src={image} alt="" />
                    </div>

                    <div className={`${id % 2 === 0 ? 'lg:mx-auto flex justify-end' : ''} lg:w-[50%] lg:my-auto`}>

                        <div className=' lg:w-[70%]'>

                            <h1 className={`${className} text-[50px] text-primary-purple leading-[68.48px] mb-5 font-normal drop-shadow-xl`}>{title}</h1>
                            <Button
                                value={btnText}
                                onClick={onClick}
                                variant='sm'
                            />
                        </div>

                    </div>

                </div>
            }
            {
                // image on the left card with email link
                type === 3 &&
                <div className={`${id % 2 === 0 ? 'lg:flex flex-row-reverse' : 'lg:flex'} w-[full] h-full mx-auto gap-10 lg:gap-0`}
                    data-aos="fade-up"
                >

                    <div className="flex justify-center lg:w-[50%] lg:mb-0 mb-10">
                        <img src={image} alt="" />
                    </div>

                    <div className={`${id % 2 === 0 ? 'lg:mx-auto flex justify-end' : ''} lg:w-[50%] lg:my-auto`}>

                        <div className=' lg:w-[70%]'>

                            <h1 className={`${className} text-[50px] text-primary-purple leading-[68.48px] mb-5 font-normal`}>{title}</h1>
                            <p className={`${fontSize} lg:text-md text-[16px] lg:w-50 font-light lg:w-50 text-[#0A1017]`}>{content}</p>
                            <a className='font-bold' href={'mailto:speakafrica.io@gmail.com?subject'} >{'speakafrica.io@gmail.com'}</a>
                        </div>

                    </div>

                </div>
            }
        </section >
    )
}
