import React from 'react'

export const Cards = ({ type, className, btnText, image, title, content, onClick, }) => {
    return (
        <section className='px-0 py-0'>
            {
                type === 1 &&
                <div className={`${className} p-5 rounded-xl w-full py-10 h-[60vh]`}
                    data-aos="zoom-in"
                >
                    <div className='w-full'>
                        <img src={image} alt="" />
                        <div className=' flex flex-col justify-between h-[90%]'>
                            <h1 className={`${className} text-[32.69px] text-primary-purple font-[400] leading-[44.46px] my-3 cardHeader`}>{title}</h1>
                            <p className={`text-[16px] leading-[21.21px] font-thin`}>{content}</p>
                        </div>
                    </div>
                </div>
            }
            {
                type === 2 &&
                <div className={`rounded-xl w-[324px] flex justify-center lg:px-5 pt-20 pb-10 h-full text-center`}
                    data-aos="zoom-in"
                >
                    <div className='lg:w-[90%] text-center'>
                        <img src={image} alt="" className='mx-auto' />
                        <h1 className={`text-[32.69px] text-primary-purple font-[400] leading-[44.46px] my-8 w-[90%] mx-auto text-center cardHeader`}>{title}</h1>
                        <p className={`lg:text-[16px] text-md leading-[21.21px] font-thin`}>{content}</p>
                    </div>
                </div>
            }
            {
                type === 3 &&
                <div className={`rounded-xl h-full border border-primary`}
                    data-aos="zoom-in"
                >
                    <img src={image} alt="" className='mx-auto w-full' />
                    <div className='text-left p-3'>
                        <h1 className={`text-[16.59px] text-primary-purple font-[400] leading-[44.46px]  cardHeader`}>{title}</h1>
                        <p className={`text-[16px] leading-[21.21px] font-thin`}>{content}</p>
                    </div>
                </div>
            }
            {
                type === 4 &&
                <div className={`rounded-xl w-[324px] h-full`}
                    data-aos="zoom-in"
                >
                    <img src={image} alt="" className='mx-auto rounded-xl' />
                    <div className='p-3 text-center'>
                        <h1 className={`text-[24px] text-primary-purple font-[700] leading-[68.48px] cardHeader`}>{title}</h1>
                        <p className={`text-lg leading-[21.21px] font-thin`}>{content}</p>
                    </div>
                </div>
            }
        </section>
    )
}
