import React from 'react'

export const Button = ({ variant, value, type, onClick, className }) => {
    return (
        type === 'outlined' ?
            // Outlined Button ============================================================
            <button
                className={`${variant === 'sm' ? 'lg:h-[43.75px] lg:w-[141.98px] h-[50px] w-[150px]' : 'h-[70px] w-[227.17px]'} p-[8.25472px] rounded-[5px] border-[0.825472px] border-primary-purple text-primary-purple bg-primary bg-opacity-5 hover:bg-opacity-5 transition-all duration-300`}
                onClick={() => onClick()}
                data-aos="fade-in"
            >
                <p className={`${variant === 'sm' ? 'text-[16.5094px] leading-[20px]' : 'text-[26.4151px] leading-[32px]'}`}>{value}</p>
            </button>
            :
            // Solid Button ===============================================================
            <button
                className={`${className} ${variant === 'sm' ? 'lg:h-[43.75px] lg:w-[141.98px] h-[50px] w-[150px]' : 'h-[70px] w-[227.17px]'} p-[8.25472px] rounded-[5px] bg-primary-purple text-white`}
                onClick={() => onClick()}
                data-aos="fade-in"
            >
                <p className={`${variant === 'sm' ? 'text-[16.5094px] leading-[20px]' : 'text-[26.4151px] leading-[32px]'}`}>{value}</p>
            </button>
    )
};