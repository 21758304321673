import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const languageToLearns = [
  { label: "Swahili", value: "swahili" },
  { label: "Yoruba", value: "yoruba" },
  { label: "Hausa", value: "hausa" },
  { label: "Zulu", value: "zulu" },
  { label: "Xhosa", value: "xhosa" },
  { label: "Afrikaans", value: "afrikaans" },
  { label: "Igbo", value: "igbo" },
  { label: "Amharic", value: "amharic" },
  { label: "Shona", value: "shona" },
  { label: "Oromo", value: "oromo" },
  { label: "Somali", value: "somali" },
  { label: "Tigrinya", value: "tigrinya" },
  { label: "Malagasy", value: "malagasy" },
  { label: "Igala", value: "igala" },
  { label: "Kikuyu", value: "kikuyu" },
  { label: "Kinyarwanda", value: "kinyarwanda" },
  { label: "Kirundi", value: "kirundi" },
  { label: "Lingala", value: "lingala" },
  { label: "Luganda", value: "luganda" },
  { label: "Luhya", value: "luhya" },
  { label: "Luo", value: "luo" },
  { label: "Mende", value: "mende" },
  { label: "Ndebele", value: "ndebele" },
  { label: "Nuer", value: "nuer" },
  { label: "Oshiwambo", value: "oshiwambo" },
  { label: "Pedi", value: "pedi" },
  { label: "Rukiga", value: "rukiga" },
  { label: "Runyankore", value: "runyankore" },
  { label: "Sesotho", value: "sesotho" },
  { label: "Setswana", value: "setswana" },
  { label: "Soga", value: "soga" },
  { label: "Sotho", value: "sotho" },
  { label: "Tswana", value: "tswana" },
  { label: "Umbundu", value: "umbundu" },
  { label: "Venda", value: "venda" },
  { label: "Wolof", value: "wolof" },
];

const AlertDialogSlide = () => {
  const [countries, setCountries] = useState([]);
  const [countrySearch, setCountrySearch] = useState("");
  const [filteredCountry, setFilteredCountry] = useState([]);
  const [searchLangTerm, setSearchLangTerm] = useState("");
  const [filteredLanguageToLearns, setFilteredLanguageToLearns] = useState([]);

  useEffect(() => {
    axios
      .get("https://countriesnow.space/api/v0.1/countries/flag/images")
      .then((result) => {
        setCountries(result.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
    setTimeout(() => {
      handleClickOpen();
    }, 1000);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      fullName: "",
      country: "",
      languageToLearn: "",
    },
    onSubmit: (values) => {
      console.log(values);
      fetch('https://speak-africa-api.herokuapp.com/api/v1/waitlist', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Response:', data);
      toast.success("You have been added to the wait list!");
      
    })
    .catch(error => {
      console.log('Error:', error);
    });
      setOpen(false);
    },
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const handleCountrySearch = (event) => {
    setCountrySearch(event.target.value);
    const filteredCount = countries.filter((country) =>
      country.name.toLowerCase().includes(countrySearch.toLowerCase())
    );
    setFilteredCountry(filteredCount);
  };

  const handleSelectCountry = (selectedCountry) => {
    setCountrySearch("");
    setFilteredCountry([]);
    formik.setFieldValue("country", selectedCountry.name);
  };

  const handleLanguageToLearnChange = (e) => {
    const input = e.target.value.toLowerCase();
    setSearchLangTerm(input);

    const filtered = languageToLearns.filter((languageToLearn) =>
      languageToLearn.label.toLowerCase().includes(input)
    );
    setFilteredLanguageToLearns(filtered);
  };

  const handleSelectLanguageToLearn = (selectedLanguageToLearn) => {
    setSearchLangTerm("");
    setFilteredLanguageToLearns([]);
    formik.setFieldValue("languageToLearn", selectedLanguageToLearn.label);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        // BackdropProps={{
        //     style: { backgroundColor: 'rgba(110, 99, 237, 0.2)' },
        // }}
      >
        <div className="bg-white md:w-[600px] mx-auto rounded-xl">
          <div
            onClick={handleClose}
            className=" w-max text-white p-2 mt-6 ml-[83%] md:ml-[90%] rounded bg-primary-purple"
          >
            <FaTimes />{" "}
          </div>

          <form className=" p-5 md:px-14 pb-12" onSubmit={formik.handleSubmit}>
            <h1 className="text-[1.1em] md:text-[35px] text-primary-purple lg:leading-[68.48px] drop-shadow-lg font-bold">
              Join Our Waitlist
            </h1>
            <div className="p-2">
              <div class="relative py-2">
                <input
                  type="text"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  id="float1"
                  className="h-[55px] block sm:px-2.5 pb-2.5 p-2 pt-4 w-[65vw] sm:w-full text-sm text-gray-900 bg-transparent rounded-lg border-[1px] border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  for="float1"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Email
                </label>
              </div>

              <div className="relative py-2">
                <input
                  type="text"
                  name="fullName"
                  onChange={formik.handleChange}
                  value={formik.values.fullName}
                  id="float2"
                  className="h-[55px] block sm:px-2.5 pb-2.5 p-2 pt-4 w-[65vw] sm:w-full text-sm text-gray-900 bg-transparent rounded-lg border-[1px] border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  for="float2"
                  className="absolute text-[0.95em] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Full Name (Surname Last)
                </label>
              </div>

              <div className="relative py-2">
                <input
                  type="text"
                  id="float3"
                  placeholder=" "
                  name="country"
                  onKeyUp={handleCountrySearch}
                  onChange={formik.handleChange}
                  value={formik.values.country}
                  className="h-[55px] block sm:px-2.5 pb-2.5 p-2 pt-4 w-[65vw] sm:w-full text-sm text-gray-900 bg-transparent rounded-lg border-[1px] border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  required
                />
                <label
                  for="float3"
                  className="absolute text-[0.95em] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Country
                </label>
                {filteredCountry.length > 0 && (
                  <span className="list-group static">
                    {filteredCountry.map((country) => (
                      <span
                        key={country.name}
                        className=""
                        onClick={() => handleSelectCountry(country)}
                      >
                          <div className="flex gap-2 items-center">
                          <i> <img
                          src={country.flag}
                          // alt={country.name}
                          style={{ width: "30px" }}
                        /></i>
                         <i className="mt-2 cursor-pointer">{country.name}</i>
                          </div>
                      </span>
                    ))}
                  </span>
                )}
              </div>
            

              <div className="relative py-2">
                <input
                  name="languageToLearn"
                  onKeyUp={handleLanguageToLearnChange}
                  onChange={formik.handleChange}
                  value={formik.values.languageToLearn}
                  type="text"
                  id="float4"
                  placeholder=" "
                  className="h-[55px] block sm:px-2.5 pb-2.5 p-2 pt-4 w-[65vw] sm:w-full text-sm text-gray-900 bg-transparent rounded-lg border-[1px] border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  required
                />
                <label
                  for="float4"
                  className="absolute text-[0.95em] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Language you wish to learn
                </label>
                {filteredLanguageToLearns.length > 0 && (
                  <ul className="list-group">
                    {filteredLanguageToLearns.map((languageToLearn, index) => (
                      <li
                        key={index}
                        className="list-group-item"
                        onClick={() => handleSelectLanguageToLearn(languageToLearn)}
                      >
                        <div className="mt- cursor-pointer">
                        {languageToLearn.label}
                        </div>
                        
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="mt-6 md:mt-10 py-2 px-14 bg-primary-purple rounded text-white text-sm flex justify-center mx-auto"
            >
              Submit
            </button>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default AlertDialogSlide;
