import React from 'react'

function Chat() {
    return (
        <div className="fixed z-50 bottom-0 right-5 hover:-translate-y-2 transition-all duration-300">
            <a href="https://wa.me/message/MESNU4FUL5YPP1 " rel='noreferrer noopener' target='blank'>
                <svg width="111" height="122" viewBox="0 0 111 122" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_dddd_122_758)">
                        <circle cx="55.5" cy="45.5" r="42.5" fill="#6E63ED" />
                    </g>
                    <path d="M62.9872 36.1325C61.7312 33.1841 59.495 30.7603 56.6572 29.2713C53.8194 27.7822 50.5542 27.3195 47.4144 27.9614C44.2746 28.6032 41.453 30.3103 39.4272 32.7936C37.4014 35.2768 36.2959 38.3838 36.2977 41.5886V53.0567C36.2977 53.7019 36.554 54.3206 37.0102 54.7768C37.4664 55.233 38.0852 55.4893 38.7304 55.4893H48.5825C49.6678 57.9651 51.4498 60.0717 53.7112 61.5526C55.9726 63.0335 58.6159 63.8247 61.3191 63.8298H72.7872C73.4324 63.8298 74.0512 63.5735 74.5074 63.1173C74.9636 62.6611 75.2199 62.0423 75.2199 61.3972V49.929C75.2265 46.5297 73.9842 43.2464 71.729 40.7029C69.4738 38.1594 66.3629 36.5329 62.9872 36.1325ZM72.4397 61.0496H61.3191C59.3528 61.0475 57.4221 60.5256 55.7224 59.537C54.0228 58.5483 52.6147 57.128 51.6407 55.4198C55.0571 55.0557 58.2186 53.4423 60.5181 50.8895C62.8175 48.3366 64.0929 45.0243 64.0993 41.5886C64.0954 40.7554 64.0198 39.924 63.8734 39.1038C66.312 39.6793 68.4848 41.0615 70.0396 43.0263C71.5944 44.9911 72.4402 47.4234 72.4397 49.929V61.0496Z" fill="#FDFCFF" />
                    <defs>
                        <filter id="filter0_dddd_122_758" x="0" y="0" width="111" height="122" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.282353 0 0 0 0 0.121569 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_122_758" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="2" />
                            <feGaussianBlur stdDeviation="2.5" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.282353 0 0 0 0 0.121569 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="effect1_dropShadow_122_758" result="effect2_dropShadow_122_758" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="9" />
                            <feGaussianBlur stdDeviation="4.5" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.282353 0 0 0 0 0.121569 0 0 0 0.09 0" />
                            <feBlend mode="normal" in2="effect2_dropShadow_122_758" result="effect3_dropShadow_122_758" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="21" />
                            <feGaussianBlur stdDeviation="6.5" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.282353 0 0 0 0 0.121569 0 0 0 0.05 0" />
                            <feBlend mode="normal" in2="effect3_dropShadow_122_758" result="effect4_dropShadow_122_758" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_122_758" result="shape" />
                        </filter>
                    </defs>
                </svg>

            </a>
        </div>
    )
}

export default Chat