import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from "react";
import ngn from "../assets/Img/ngn.png";
import axios from "axios";
// import Button from '@mui/material/Button';
// import DialogActions from '@mui/material/DialogActions';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { Slide } from '@mui/material';

export default function ResponsiveDialog() {
    const [open, setOpen] = React.useState(false);
    // const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [flags, setFlags] = useState([]);
    const [searchCountry, setSearchCountry] = useState("");

    useEffect(() => {
        axios.get(`https://restcountries.com/v2/all`).then((res) => {
            if (res.status === 200) {
                setFlags(res?.data)
            }
            else {
                console.log("An error occur");
            }
        }).catch((err) => {
            //   seterror(err);
        })
    }, []);

    const [image, setImage] = useState(ngn)
    const handleImage = (e) => {
        console.log(e);
        setImage(e)
        // setToggle(false)
    }

    return (
        <div>
            <button className="flex items-center text-sm font-medium text-black rounded md:mr-0 focus:ring-none focus:ring-none"
                type="button"
                onClick={handleClickOpen}>
                <span className="sr-only">Open user menu</span>
                <img src={image} alt="Logo" className="rounded-[100%] w-[40px] h-[40px] p-2 ml-auto block object-cover bg-primary bg-opacity-25 hover:bg-opacity-30 transition-all duration-300" />
            </button>
            <Dialog
                open={open}
                overlayStyle={{ backgroundColor: 'transparent' }}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Select Your Country 🗺"}
                    <form className="block lg:hidden text-left">
                        <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                        <div className="relative m-auto">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                                <svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                </svg>
                            </div>
                            <input
                                type="search"
                                id="default-search"
                                className="block w-full py-3 lg:py-4 p-2 pl-12 text-sm text-gray-900 border border-[#E2E0FB] rounded-md focus:border-[#E2E0FB] focus:outline-none bg-[#6E63ED] bg-opacity-[0.2]"
                                placeholder="Search for country..."
                                value={searchCountry} onChange={(e) => setSearchCountry(e.target.value)}
                            />
                        </div>
                    </form>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <ul className="text-center text-sm text-black flex flex-wrap items-center dark:text-gray-200 overflow-y-auto scrol mx-auto" aria-labelledby="dropdownInformdropdownAvatarNameButtonationButton">
                            {flags?.filter((flag) => {
                                if (searchCountry === '') {
                                    return flag
                                } else if (flag.name.includes(searchCountry)) {
                                    return flag
                                }
                                else {
                                    return "Country does not exist"
                                }
                            }).map(({ region, flag, alpha3Code }, id) => (
                                <li className="text-black text-[10px] py-2" key={id} onClick={handleClose}>
                                    {
                                        region === 'Africa' &&
                                        <div className='m-auto cursor-pointer' onClick={(e) => handleImage(flag)}>
                                            <img className="rounded-[100%] w-[50px] h-[50px] p-2 m-auto block object-cover" src={flag} alt="" /> <span className="">{alpha3Code
                                            }</span>
                                        </div>
                                    }
                                </li>
                            ))}
                        </ul>
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Disagree
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions> */}
            </Dialog>
        </div>
    );
}