import {React, useEffect} from 'react'

const WaitlistData = () => {
  useEffect(() => {
    fetch('https://speak-africa-api.herokuapp.com/api/v1/waitlist', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log('Response:', data);      
    })
    .catch(error => {
      console.log('Error:', error);
    });
  }, [])
  
  const waitListData = [
    {
      email: 'ogunwoleabraham@gmail.com',
      fullname: 'Ben Ajani',
      country: 'Nigeria',
      language: 'China',
    },
    {
      email: 'ogunwoleabraham@gmail.com',
      fullname: 'Ben Ajani',
      country: 'Nigeria',
      language: 'China',
    },
    {
      email: 'ogunwoleabraham@gmail.com',
      fullname: 'Ben Ajani',
      country: 'Nigeria',
      language: 'China',
    },
    {
      email: 'ogunwoleabraham@gmail.com',
      fullname: 'Abraham Ogunwole',
      country: 'Nigeria',
      language: 'China',
    },
    {
      email: 'ogunwoleabraham@gmail.com',
      fullname: 'Abraham Ogunwole',
      country: 'Nigeria',
      language: 'China',
    },
    {
      email: 'ogunwoleabraham@gmail.com',
      fullname: 'Abraham Ogunwole',
      country: 'Nigeria',
      language: 'China',
    },
    {
      email: 'ogunwoleabraham@gmail.com',
      fullname: 'Abraham Ogunwole',
      country: 'Nigeria',
      language: 'China',
    },
  ]

  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg pt-24 bg-white z-10 ">
      <h1 className="lg:text-[42px] text-[35.1915px] text-primary-purple font-extrabold drop-shadow-lg custom relative text-center">
        WaitLists
      </h1>
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
          <tr>
            <th scope="col" class="px-6 py-3">
              Email
            </th>
            <th scope="col" class="px-6 py-3">
              Full Name
            </th>
            <th scope="col" class="px-6 py-3">
              Country
            </th>
            <th scope="col" class="px-6 py-3">
              Language
            </th>
          </tr>
        </thead>
        <tbody>
          {waitListData.map((data) => (
            <tr
              key={data.email}
              class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
            >
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {data.email}
              </th>
              <td class="px-6 py-4">{data.fullname}</td>
              <td class="px-6 py-4">{data.language}</td>
              <td class="px-6 py-4">{data.country}</td>
              
            </tr>
            
          ))}
        </tbody>
      </table>
      
    </div>
  )
}

export default WaitlistData
