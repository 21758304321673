import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
import Home from './pages/Home'
import OurSolution from './pages/OurSolution'
import OurTeam from './pages/OurTeam'
import Ellispe1 from './assets/Img/background/Ellipse1.png'
import Ellispe2 from './assets/Img/background/Ellipse2.png'
import FAQ from './pages/FAQ'

import AOS from 'aos';
import 'aos/dist/aos.css';
import AlertDialogSlide from './components/Dialog'
import Chat from './components/Chat'
import WaitlistData from './components/WaitlistData'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

AOS.init();

function App() {
  return (
    <section className='relative bg'>
      <img src={Ellispe1} alt="" className='absolute top-0 right-0 z-[1]' />
      <img src={Ellispe2} alt="" className='absolute top-52 lg:right-[45%] z-[1]' />
      <Chat />

      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/team' element={<OurTeam />} />
        <Route path='/solution' element={<OurSolution />} />
        <Route path='/frequentlyAskedQuestions' element={<FAQ />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path="/list/list" element={<WaitlistData />}/>
      </Routes>
      <Footer />
      {/* Waitlist */}
      <AlertDialogSlide />
      <ToastContainer/>
    </section>
  )
}
export default App