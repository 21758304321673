import React, { useEffect } from 'react'
import { Cards } from '../components/Cards'
import { Header } from '../components/Header'
import One from "../assets/Img/icons/1.png";
import Two from "../assets/Img/icons/2.png";
import Three from "../assets/Img/icons/3.png";
import four from "../assets/Img/icons/4.png";
import five from "../assets/Img/icons/5.png";
import six from "../assets/Img/icons/6.png";
import Hand from "../assets/Img/icons/hand.png";
import Clock from "../assets/Img/icons/clock.png";
import Phone from "../assets/Img/icons/phone.png";
import PhoneSection from '../components/PhoneSection';

const OurSolution = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });//always scroll to top
        document.title = 'Speak Africa | Our Solution'
    })
    const offers1 = [
        {
            num: One,
            title: 'Easy EARNINGS',
            content: 'Our products are centered on the user\'s experience and their reward for engagement. for both learner and tutor'
        },
        {
            num: Two,
            title: ' One On One Class',
            content: "Our products are centered on the user's experience and their reward for engagement. for both learner and tutor"
        },
        {
            num: Three,
            title: 'We Promote African Culture',
            content: "Our products are centered on the user's experience and their reward for engagement. for both learner and tutor"
        },
    ]
    const offers2 = [

        {
            num: four,
            title: 'We Translator',
            content: "Our products are centered on the user's experience and their reward for engagement. for both learner and tutor"
        },
        {
            num: five,
            title: 'Free Books',
            content: "Our products are centered on the user's experience and their reward for engagement. for both learner and tutor"
        },
        {
            num: six,
            title: 'We connect',
            content: "Our products are centered on the user's experience and their reward for engagement. for both learner and tutor"
        },
    ]
    return (
        <div className='min-w-screen mb-4 relative z-20'>

            <section className='w-full pt-30 pb-10 lg:pb-0'>

                <div className="lg:w-[95vw] w-[85vw] mx-auto gap-10 md:gap-0">

                    <section className='w-full pt-36 pb-10 mb-20'>
                        <div className="lg:w-[85vw] w-[85vw] mx-auto gap-10 md:gap-0 text-center">
                            <Header
                                variant={1}
                                title={'What we offer'}
                            />

                            <div className='flex lg:flex-row flex-col lg:gap-0 gap-10 items-center justify-between mt-10 w-[80%] mx-auto mb-1'>

                                {offers1.map(({ num, title, content }) => (
                                    <Cards
                                        type={2}
                                        image={num}
                                        className={'bg-[#E9CBB5]'}
                                        title={title}
                                        content={content}
                                    />
                                ))}
                            </div>

                            <div className='flex lg:flex-row flex-col lg:gap-0 gap-10 items-center justify-between mt-5 w-[80%] mx-auto mb-1'>

                                {offers2.map(({ num, title, content }) => (
                                    <Cards
                                        type={2}
                                        image={num}
                                        className={'bg-[#E9CBB5]'}
                                        title={title}
                                        content={content}
                                    />
                                ))}
                            </div>
                        </div>
                    </section>

                    {/* Why Speak Africa */}
                    <section className='w-full py-10 mb-20'>
                        <div className="lg:w-[85vw] w-[85vw] mx-auto gap-10 md:gap-0">

                            <Header
                                variant={1}
                                title={'Why Speak Africa'}
                                subtitle={"Speak Africa seeks to preserve the future of Africa’s culture & values by enabling it's retainment leveraging technology."}
                            />

                            <div className='grid lg:grid-cols-3 md:grid-cols-3 gap-10 items-center justify-between mt-20 lg:w-[80%] mx-auto'>
                                <Cards
                                    type={1}
                                    image={Hand}
                                    className={'bg-[#ECF2FE]'}
                                    title={'User-Friendly Interface'}
                                    content={'From mastering your language of choice to connecting with and building a community, Speak Africa offers you ease of access. '}
                                    data-aos="fade-up"
                                    data-aos-delay="400"
                                />
                                <Cards
                                    type={1}
                                    image={Phone}
                                    className={'bg-[#DFEFE6]'}
                                    title={'User-Friendly Interface'}
                                    content={'Learn African Language in any format; video-class, audio-class, explainer, with AR/VR and much more, whatever works for you? Speak Africa’s got you.'}
                                    data-aos="fade-up"
                                    data-aos-delay="500"
                                />
                                <Cards
                                    type={1}
                                    image={Clock}
                                    className={'bg-[#E9CBB5]'}
                                    title={'User-Friendly Interface'}
                                    content={'Enjoy a variety of alternatives, live classes, one-on-one classes with language instructors, or group classes where you learn and connect with other learners from different countries all in the palm of your hand, your pace, your rules. '}
                                    data-aos="fade-up"
                                    data-aos-delay="600"
                                />
                            </div>
                        </div>
                    </section>
                    {/* End of Why Speak Africa */}

                    <section className='w-full pt-36 pb-10 lg:pb-0 mb-20'>
                        <div className="w-[85vw] mx-auto gap-10 md:gap-0">
                            <PhoneSection />
                        </div>
                    </section>

                </div>

            </section>

        </div>
    )
}

export default OurSolution