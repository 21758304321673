import React, { useEffect } from 'react'
import { Header } from '../components/Header'
import Hero from '../components/Hero'
import { Section } from '../components/section'
import { Cards } from '../components/Cards';
// Images ======>
import Video from "../assets/Img/section/video.png";
import Translate from "../assets/Img/section/translate.png";
import Live from "../assets/Img/section/live.png";
import Rocket from "../assets/Img/section/rocket.png";
import Books from "../assets/Img/section/books.png";
import Hand from "../assets/Img/icons/hand.png";
import Clock from "../assets/Img/icons/clock.png";
import Phone from "../assets/Img/icons/phone.png";
import One from "../assets/Img/icons/1.png";
import Two from "../assets/Img/icons/2.png";
import Three from "../assets/Img/icons/3.png";
// import Learn from "../assets/Img/icons/learn.png";
import liveChat from "../assets/Img/icons/liveChat.png";
import shake from "../assets/Img/icons/shake.png";
// import { Button } from '../components/Button';
import PhoneSection from '../components/PhoneSection';
import { NavLink } from 'react-router-dom';
//===============>

function Home() {

    useEffect(() => {
        window.scrollTo(
            {
                top: 0,
                left: 0,
                behavior: 'smooth'
            }
        );
        document.title = 'Speak Africa | Learn Africa Language And Culture With Ease'
    })

    // Section card content ======================>>>>>>>>>>>>>>
    const SectionContent = [
        {
            id: 1,
            image: Video,
            title: 'Video-Based Content',
            content: 'Learn any African language on the go either one on one video live video class with an instructor or in a group class where you are also able to connect with other students, chat and strengthen your communication with each other.'
        },
        {
            id: 2,
            image: Translate,
            title: 'Translate',
            content: 'Effortlessly translate your favorite movies, videos, audios, pdfs, and texts from English Language to your African choice language, and from African language to other African languages.'
        },
        {
            id: 3,
            image: Live,
            title: 'Live Class',
            content: 'Enjoy and learn your favorite African Language live as you connect with other students from other African Countries and Continents across the globe.'
        },
        {
            id: 4,
            image: Rocket,
            title: 'Spaces',
            content: 'Start a conversation, build a community, support a cause, share your knowledge or entertain your friends with access to a global audience on Speak Africa space.'
        },
        {
            id: 5,
            image: Books,
            title: 'E- books',
            content: 'Access a variety of African poetry, artwork, books, songs and documentaries all in one spot.'
        },
    ];
    // End of Card section ===================================>>>>>>>>>>>

    return (
        <div className='min-w-screen relative'>

            <Hero />

            {/* Our features section */}
            <section className='w-full pt-36 pb-10 lg:pb-0 mb-20'>
                <div className="lg:w-[95vw] w-[85vw] mx-auto gap-10 md:gap-0">

                    <Header
                        variant={1}
                        title={'Our Features'}
                        subtitle={'To give you a beautiful experience, you will enjoy the Speak Africa product in a variety of ways. Check out these cool functions.'}
                    />

                    {
                        SectionContent.map((content, i) => {
                            return (
                                <Section
                                    type={1}
                                    key={i}
                                    title={content.title}
                                    content={content.content}
                                    image={content.image}
                                    id={content.id}
                                />
                            )
                        })
                    }

                </div>
            </section>
            {/*End of Our features section */}

            {/* Why Speak Africa */}
            <section className='w-full py-10 mb-20'>
                <div className="lg:w-[85vw] w-[85vw] mx-auto gap-10 md:gap-0">

                    <Header
                        variant={1}
                        title={'Why Speak Africa'}
                        subtitle={"Speak Africa seeks to preserve the future of Africa’s culture & values by enabling it's retainment leveraging technology."}
                    />

                    <div className='grid lg:grid-cols-3 md:grid-cols-3 gap-10 items-center justify-between mt-20 lg:w-[80%] mx-auto'>
                        <Cards
                            type={1}
                            image={Hand}
                            className={'bg-[#ECF2FE]'}
                            title={'User-Friendly Interface'}
                            content={'From mastering your language of choice to connecting with and building a community, Speak Africa offers you ease of access. '}
                            data-aos="fade-up"
                            data-aos-delay="400"
                        />
                        <Cards
                            type={1}
                            image={Phone}
                            className={'bg-[#DFEFE6]'}
                            title={'SimplifIed Learning'}
                            content={'Learn African Language in any format; video-class, audio-class, explainer, with AR/VR and much more, whatever works for you? Speak Africa’s got you.'}
                            data-aos="fade-up"
                            data-aos-delay="500"
                        />
                        <Cards
                            type={1}
                            image={Clock}
                            className={'bg-[#E9CBB5]'}
                            title={'Learning Flexibility'}
                            content={'Enjoy a variety of alternatives, live classes, one-on-one classes with language instructors, or group classes where you learn and connect with other learners from different countries all in the palm of your hand, your pace, your rules. '}
                            data-aos="fade-up"
                            data-aos-delay="600"
                        />
                    </div>
                </div>
            </section>
            {/* End of Why Speak Africa */}

            {/* What we offer */}
            <section className='w-full py-10 mb-20'>
                <div className="w-[85vw] mx-auto gap-10 md:gap-0 text-center">
                    <Header
                        variant={1}
                        title={'What we offer'}
                    />

                    <div className='flex lg:flex-row flex-col lg:gap-0 gap-10 items-center justify-between w-[80%] mx-auto pb-10'>

                        <Cards
                            type={2}
                            image={One}
                            className={'bg-[#E9CBB5]'}
                            title={'Easy EARNINGS'}
                            content={'You can now make money from your passion as a language instructor or as one of our official translators. You can never miss a chance to improve your finances'}
                        />

                        <Cards
                            type={2}
                            image={Two}
                            className={'bg-[#E9CBB5]'}
                            title={'One On One Class'}
                            content={'You have the singular access to any language or instructor of your choice in a one-on-one session to hasten your progress or to hire a translator for official purposes within any African countries.'}
                        />

                        <Cards
                            type={2}
                            image={Three}
                            className={'bg-[#E9CBB5]'}
                            title={'We Promote African Culture'}
                            content={'Africa is blessed with great cultural values, etiquettes and vibes; Apeak Africa is focused on delivering the best practices across African countries effortlessly.'}
                        />

                    </div>
                    <NavLink to="/solution" className="lg:h-[43.75px] lg:w-[141.98px] h-[50px] w-[150px] px-[35px] py-4 rounded-[5px] bg-primary-purple text-white ">See more</NavLink>
                    {/* <Link to="/solution" className='lg:h-[43.75px] lg:w-[141.98px] h-[50px] w-[150px] p-[200px] rounded-[5px] bg-primary-purple text-white '>See more</Link> */}
                </div>
            </section>
            {/* End of what we offer */}

            {/* Learn Better On How To Be An Instructor */}
            {/* <section className='w-full py-10 mb-20'>
                <div className="lg:w-[85vw] w-[85vw] lg:mx-auto gap-10 md:gap-0 text-center">
                    <Header
                        variant={1}
                        title={'Learn Better On How To Be An Instructor'}
                    />

                    <div className='grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-10 items-center justify-between my-20 lg:w-[90%] mx-auto'>

                        <Cards
                            type={3}
                            image={Learn}
                            className={'bg-[#E9CBB5] w-full'}
                            title={'Easy EARNINGS'}
                            content={'Lorem ipsum dolor sit amet consectetur. Adipiscing tellus vitae vulputate risus facilisis facilisi. Et lectus elementum tincidun ........'}
                        />

                        <Cards
                            type={3}
                            image={Learn}
                            className={'bg-[#E9CBB5]'}
                            title={'Easy EARNINGS'}
                            content={'Lorem ipsum dolor sit amet consectetur. Adipiscing tellus vitae vulputate risus facilisis facilisi. Et lectus elementum tincidun ........'}
                        />

                        <Cards
                            type={3}
                            image={Learn}
                            className={'bg-[#E9CBB5]'}
                            title={'Easy EARNINGS'}
                            content={'Lorem ipsum dolor sit amet consectetur. Adipiscing tellus vitae vulputate risus facilisis facilisi. Et lectus elementum tincidun ........'}
                        />
                    </div>
                    <Button
                        value={'See More'}
                        data-aos="fade-up"
                        data-aos-delay="1000"
                        variant={'sm'}

                    />
                </div>
            </section> */}
            {/* End of learn better */}


            {/* Section */}
            <section className='w-full py-10 lg:pb-0'>
                <div className="lg:w-[95vw] w-[85vw] mx-auto gap-10 md:gap-0 pb-10">
                    <Section
                        type={2}
                        title={'We’re Always Available & Ready to Help You'}
                        image={liveChat}
                        btnText='Live Chat'
                    />
                </div>
            </section>

            <section className='w-full pt-36 pb-10 lg:pb-0 mb-20'>
                <div className="w-[85vw] mx-auto gap-10 md:gap-0">
                    <PhoneSection />
                </div>
            </section>

            <section className='w-full py-10 lg:pb-0 mb-20'>
                <div className="lg:w-[95vw] w-[85vw] mx-auto gap-10 md:gap-0">

                    <Section
                        type={3}

                        title={'Do You Wish To Partner With Us?'}
                        image={shake}
                        content='For partnership and investment, email us at:'

                    />
                </div>
            </section>

        </div>
    )
}

export default Home