import React from 'react'

export const Header = ({ title, subtitle, className, fontSize, variant }) => {
    return (
        <section>
            {
                variant === 1 &&
                <header className='text-center w-full lg:w-[70%] mx-auto lg:mb-10'>
                    <h1 className={`${className} text-[3em] lg:text-[55px] text-primary-purple lg:leading-[68.48px] drop-shadow-lg font-bold`}
                        data-aos="fade-in"
                    >
                        {title}
                    </h1>

                    <p className={`${fontSize} text-[16px] lg:text-md lg:w-50`}
                        data-aos="fade-in"
                    >
                        {subtitle}
                    </p>
                </header>
            }
            {
                variant === 2 &&
                <header className='text-center w-full lg:w-[70%] mx-auto lg:mb-10'>
                    <h1 className={`${className} text-[24px] text-primary-purple leading-[68.48px] drop-shadow-lg font-bold`}
                        data-aos="fade-in"
                    >
                        {title}
                    </h1>
                    <p className={`${fontSize} text-md lg:w-50`}
                        data-aos="fade-in"
                    >
                        {subtitle}
                    </p>
                </header >
            }
        </section >
    )
}
