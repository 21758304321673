import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

export default function ControlledAccordions({ accordionTitle, accordionContent }) {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className='mb-5'
            data-aos="fade-in"
        >
            <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                className='drop-shadow-xl lg:p-5 p-3 border border-primary'
                style={{ boxShadow: "none", borderRadius: '17.7679px' }}
            >

                <AccordionSummary
                    expandIcon={
                        expanded ?
                            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.898926" y="0.381226" width="37.0165" height="37.0165" rx="18.5083" fill="#6E63ED" />
                                <path d="M12.3743 25.5525L25.7002 12.2266" stroke="#FDFCFF" stroke-width="1.48066" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M25.7002 25.5525L12.3743 12.2266" stroke="#FDFCFF" stroke-width="1.48066" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            :
                            <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_599_756)">
                                    <rect x="9.09229" y="7.67358" width="37.0165" height="37.0165" rx="18.5083" fill="#FDFCFF" shape-rendering="crispEdges" />
                                    <path d="M27.2305 19.252V32.5779" stroke="#6E63ED" stroke-width="1.48066" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M20.5679 25.9149H33.8938" stroke="#6E63ED" stroke-width="1.48066" stroke-linecap="round" stroke-linejoin="round" />
                                    <rect x="9.46245" y="8.04375" width="36.2762" height="36.2762" rx="18.1381" stroke="#FDFCFF" stroke-width="0.74033" shape-rendering="crispEdges" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_599_756" x="0.208324" y="0.270283" width="54.7845" height="54.7844" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dy="1.48066" />
                                        <feGaussianBlur stdDeviation="4.44198" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.078875 0 0 0 0 0.0770833 0 0 0 0 0.166667 0 0 0 0.08 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_599_756" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_599_756" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >

                    <Typography sx={{ flexShrink: 1 }} className='font-bold text-primary'>
                        {accordionTitle}
                    </Typography>

                    {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}

                </AccordionSummary>

                <AccordionDetails>

                    <Typography>
                        {accordionContent}
                    </Typography>

                </AccordionDetails>

            </Accordion>
        </div>
    );
}