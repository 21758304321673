import React, { useEffect } from 'react'
import { Header } from '../components/Header'
import PhoneSection from '../components/PhoneSection';
import { Section } from '../components/section'
import liveChat from "../assets/Img/icons/liveChat.png";
import shake from "../assets/Img/icons/shake.png";
// import { Cards } from '../components/Cards';
// import Img from "../assets/Img/team/img.png";


function AboutUs() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });//always scroll to top
        document.title = 'Speak Africa | About Us'
    })
    return (
        <div className='relative z-20'>

            <section className='w-full pt-36 pb-10 lg:pb-0 lg:mb-10'>
                <div className="lg:w-[95vw] w-[85vw] mx-auto gap-10 md:gap-0">
                    <div className='flex flex-col gap-10'>

                        <Header
                            title={'About Us'}
                            subtitle="Speak Africa helps you learn African languages and culture on the go. The app is designed to be simple and easy to use, so you can get started right away. There are a variety of features to help you learn, including audio and video lessons, grammar and vocabulary exercises, games, and more. Whether you're traveling for business or pleasure, learning a few key phrases in the local language is always a good idea. But if you're planning a trip to Africa, you might be wondering which language you should learn. There are more than 2,000 languages spoken on the continent, so it can be tough to decide which one to focus on. But don't worry – we're here to help! The app is free to download and use, and there are no ads. You can also upgrade to a premium subscription to get access to even more features, including offline mode, listening and reading comprehension exercises, and more."
                            variant={1}
                        />

                        <Header
                            title={'Our Mission'}
                            subtitle="Our mission is to make African languages easily accessible and available for the sovereignty of Africa's future.To enhance the value of Africa language, culture and heritage through this digital age To exhibit the advantage of  Africa language via technology in the Africa countries and in disporal for Africa language future sustainability  To create job opportunities for thousands upon thousands of African people in Africa language translation, interpreter, teaching, content services, Educational Services, cartoon/animation  e.t.c by retaining the value of our language, culture and our heritage."
                            variant={2}
                        />

                        <Header
                            title={'Our Vision'}
                            subtitle='Our vision is to create open opportunities for everyone all over the world to succeed and achieve more.'
                            variant={2}
                        />

                    </div>
                </div>
            </section>


            <section className='w-full py-10 lg:pb-0'>
                <div className="lg:w-[95vw] w-[85vw] mx-auto gap-10 md:gap-0 pb-10">
                    <Section
                        type={2}
                        title={'We’re Always Available & Ready to Help You'}
                        image={liveChat}
                        btnText='Live Chat'
                    />
                </div>
            </section>

            <section className='w-full pt-36 pb-10 lg:pb-0 mb-20'>
                <div className="w-[85vw] mx-auto gap-10 md:gap-0">
                    <PhoneSection />
                </div>
            </section>

            <section className='w-full py-10 lg:pb-0 mb-20'>
                <div className="lg:w-[95vw] w-[85vw] mx-auto gap-10 md:gap-0">

                    <Section
                        type={3}

                        title={'Do You Wish To Partner With Us?'}
                        image={shake}
                        content='For partnership and investment, email us at:'
                        link={'speakafrica@gmail.com'}
                        linkText='speakafrica@gmail.com'
                    />
                </div>
            </section>

        </div>
    )
}

export default AboutUs