import React, { useEffect } from 'react'
import { Cards } from '../components/Cards';
import Hand from "../assets/Img/icons/hand.png";
import Clock from "../assets/Img/icons/clock.png";
import Phone from "../assets/Img/icons/phone.png";
import { Header } from '../components/Header'
import PhoneSection from '../components/PhoneSection';
import ControlledAccordions from '../components/ControlledAccordions';


function FAQ() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });//always scroll to top
        document.title = 'Speak Africa | Frequently Asked Questions'
    })

    return (
        <div className='relative z-20'>
            {/* Accordion */}
            <section className='w-full py-10 pt-36 mb-20'>
                <div className="lg:w-[75vw] w-[85vw] mx-auto flex flex-col gap-5">
                    <Header
                        variant={1}
                        title={'Frequently Asked Questions'}
                    />

                    <div>

                        <ControlledAccordions
                            accordionTitle={'What languages are available to learn on Speak Africa?'}
                            accordionContent={
                                <p className='text-[13.3259px]'>All African languages to the grassroots.</p>
                            }
                        />
                        <ControlledAccordions
                            accordionTitle={'How does Speak Africa help learners to understand and speak African languages?'}
                            accordionContent={
                                <p className='text-[13.3259px]'>It helps learners to understand African languages by the virtue of our available features which are videos,  online classes e.t.c and professionally approved instructors, and planning to link it up the trending technology in the future Ar/VR.</p>
                            }
                        />
                        <ControlledAccordions
                            accordionTitle={'What are the benefits of learning an African language on Speak Africa?'}
                            accordionContent={
                            <ul className='list-disc text-[13.3259px]' >
                            <p className='text-[13.3259px] font-bold'>The Benefits:</p>
                                <li className='mx-6'>Interactive class (chatroom) to smooth your learning with others</li>
                                <li className='mx-6'>Certification will be made available </li>
                                <li className='mx-6'>African games and integrating other popular African systems of teaching in a fun </li>
                                <li className='mx-6'>There is a progression system on the application before you can move to the subsequent class as per the learners</li>
                            </ul>
                              }
                        />
                        <ControlledAccordions
                            accordionTitle={'What types of content are available to help learn African languages?'}
                            accordionContent={
                            <ul className='list-disc text-[13.3259px]'>
                            <p className='text-[13.3259px] font-bold'>Content Types Are: </p>
                                <li className='mx-6'>Video content </li>
                                <li className='mx-6'>Books </li>
                                <li className='mx-6'>Game content</li>
                                <li className='mx-6'>e.t.c</li>
                            </ul>}
                        />

                    </div>
                </div>
            </section>
            {/* End of Accordion */}

   
            {/* Why Speak Africa */}
            <section className='w-full py-10 mb-20'>
                <div className="lg:w-[85vw] w-[85vw] mx-auto gap-10 md:gap-0">

                    <Header
                        variant={1}
                        title={'Why Speak Africa'}
                        subtitle={"Speak Africa seeks to preserve the future of Africa’s culture & values by enabling it's retainment leveraging technology."}
                    />

                    <div className='grid lg:grid-cols-3 md:grid-cols-3 gap-10 items-center justify-between mt-20 lg:w-[80%] mx-auto'>
                        <Cards  
                            type={1}
                            image={Hand}
                            className={'bg-[#ECF2FE]'}
                            title={'User-Friendly Interface'}
                            content={'From mastering your language of choice to connecting with and building a community, Speak Africa offers you ease of access. '}
                            data-aos="fade-up"
                            data-aos-delay="400"
                        />
                        <Cards
                            type={1}
                            image={Phone}
                            className={'bg-[#DFEFE6]'}
                            title={'SimplifIed Learning'}
                            content={'Learn African Language in any format; video-class, audio-class, explainer, with AR/VR and much more, whatever works for you? Speak Africa’s got you.'}
                            data-aos="fade-up"
                            data-aos-delay="500"
                        />
                        <Cards
                            type={1}
                            image={Clock}
                            className={'bg-[#E9CBB5]'}
                            title={'Learning Flexibility'}
                            content={'Enjoy a variety of alternatives, live classes, one-on-one classes with language instructors, or group classes where you learn and connect with other learners from different countries all in the palm of your hand, your pace, your rules. '}
                            data-aos="fade-up"
                            data-aos-delay="600"
                        />
                    </div>
                </div>
            </section>
            {/* End of Why Speak Africa */}

            <section className='w-full pt-36 pb-10 lg:pb-0 mb-20'>
                <div className="w-[85vw] mx-auto gap-10 md:gap-0">
                    <PhoneSection />
                </div>
            </section>
        </div>
    )
}

export default FAQ